import { makeStyles } from "@material-ui/core/styles";

const useInputStyles = makeStyles((theme) => ({
  input: {
    marginTop: 15,
  },
  legend: {
    marginBottom: 15,
    fontSize: "1rem",
    marginTop: 10,
  },
  label: {
    margin: 5,
  },
}));

export default useInputStyles;
