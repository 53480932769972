import {
  Box, MobileStepper,
  Step,
  StepLabel, Stepper,
  Typography
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/styles";
import React from "react";


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "16px",
    padding: "16px 16px 32px",
    // @ts-ignore
    backgroundColor: theme.palette.background.default,
  },
  smallStepper: {
    position: "relative",
    padding: "0",
  },
  smallText: {
    marginTop: "5px",
  },
  small: {
    display: "flex",
    flexDirection: "column",
    padding: "16px 0 0 0",
    justifyContent: "center",
    alignItems: "center",
  },
}));

interface FormStepperParams {
  activeStep: number;
  steps: any[];
}

function FormStepper({ activeStep, steps }: FormStepperParams) {
  const classes = useStyles();
  const fullsize = useMediaQuery("(min-width:600px)");

  if (steps.length <= 1) return null;
  return fullsize ? (
    <Stepper className={classes.root} activeStep={activeStep} alternativeLabel>
      {steps.map((label: string) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  ) : (
      <Box className={classes.small}>
        <MobileStepper
          className={`${classes.root} ${classes.smallStepper}`}
          variant="dots"
          activeStep={activeStep}
          steps={steps.length}
          backButton={null}
          nextButton={null}
        />
        <Typography className={classes.smallText}>{steps[activeStep]}</Typography>
      </Box>
    );
}
export default FormStepper;
