import React, { FunctionComponent } from 'react';
import { useCookies } from 'react-cookie';
import { Route } from 'react-router-dom';
import Login from '../login';

interface AuthorizedRouteProps {
  component: string | FunctionComponent;
  path: string;
  props?: any;
  exact?: boolean;
}

function AuthorizedRoute({ component, path, props, exact }: AuthorizedRouteProps) {
  const [cookies, setCookie] = useCookies(['userToken']);

  if (!cookies['userToken']) {
    return <Route exact path={path} render={() => <Login referrer={path} setCookie={setCookie} />} />
  }

  return <Route exact={exact} path={path} render={() => React.createElement(component, props)} />
}

export default AuthorizedRoute;