import { makeStyles, Theme } from "@material-ui/core";

const sectionStyles = makeStyles((theme: Theme) => ({
  sectionTitle: {
    color: theme.palette.primary.main,
    marginBottom: "30px",
    fontWeight: "bold",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  introTitle: {
    fontSize: "48px",
  },
  sectionSubheading: {
    marginTop: "-15px",
    marginBottom: "30px",
    whiteSpace: "pre-line",
  },
  sectionFormHeading: {
    color: theme.palette.primary.main,
    marginTop: 15,
    fontWeight: "bold",
    marginBottom: 10,
  },
}));

export default sectionStyles;
