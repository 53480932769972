import React from 'react';
import ApiResponse from '../../shared/api-response';

interface FormProps {
  steps: [string, any, any][];
  activeStep: number;
  apiResponse: ApiResponse;
  setErrors: (step: number, errors: boolean) => void;
  updateChildValues: (step: number, values: {}) => void;
}

function Form({ steps, activeStep, apiResponse, setErrors, updateChildValues }: FormProps) {
  return (<>
    {steps.map((step, i) => {
      if (i !== steps.length - 1) {
        const props: any = {
          active: activeStep === i,
          itemNum: i,
          setErrors,
          key: step[0],
          updateChildValues,
        };

        if (i === 1) {
          props.firstName = apiResponse?.firstName;
          props.lastName = apiResponse?.lastName;
        }


        return React.createElement(step[1], props);
      }
      return React.createElement(step[1], {
        active: activeStep === i,
        key: step[0],
        summaryItems: steps.slice(1, steps.length - 1),
      });
    })}
  </>);
}

export default Form;