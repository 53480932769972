import axios from 'axios';

const authApi = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_ENDPOINT,
});

async function Authorize(
  token: string,
  setCookie: (name: string, value: string) => void
) {

  await authApi.post('/api/auth/google', { tokenId: token })
    .then((res) => {
      setCookie('userToken', res.data.token);
    });
}

export default Authorize;