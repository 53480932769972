import { Typography } from "@material-ui/core";
import React from "react";
import Validations from "../../helpers/form-validation";
import SetIds from "../../helpers/set-ids";
import useCustomForm from "../../shared/CustomForm";
import FormInput from "../../shared/form-input";
import FormProps from "../../shared/form-props";
import SortCodeInput from "../../shared/input-components/SortCodeInput";
import TextInput from "../../shared/input-components/TextInput";
import sectionStyles from "../../shared/sectionStyles";
import BankDetailsValueItems from "./bank-details-value-items";

const initialValues: BankDetailsValueItems = {
  BankDetailsPayeeAccountName: {
    label: "Payee (Account) Name",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
  },
  BankDetailsSortCode: {
    label: "Sort Code",
    value: "",
    validate: (val: string) => Validations.sortCode(val),
  },
  BankDetailsAccountNumber: {
    label: "Account Number",
    value: "",
    validate: (val: string) => Validations.accountNo(val),
  },
};

function BankDetails({
  active,
  setErrors,
  itemNum,
  updateChildValues,
}: FormProps) {
  const styles = sectionStyles();

  const init: BankDetailsValueItems = SetIds(initialValues);


  const { values, handleChange, handleBlur } = useCustomForm({
    initialValues: init,
    setErrors,
    itemNum,
    ignoreTouched: true,
    updateChildValues,
  });

  return !active ? null : (
    <>
      <Typography
        className={`${styles.sectionTitle}`}
        variant="h5"
        component="h3"
      >
        Bank Details
      </Typography>

      <Typography variant="subtitle1" component="h3">
        Please note, this must be a UK-based bank account. <br />
        Please contact payroll@and.digital if you do not yet have one and
        proceed to the next page.
      </Typography>

      <FormInput
        type={TextInput}
        obj={values.BankDetailsPayeeAccountName}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <FormInput
        type={SortCodeInput}
        obj={values.BankDetailsSortCode}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <FormInput
        type={TextInput}
        obj={values.BankDetailsAccountNumber}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </>
  );
}

export default BankDetails;
