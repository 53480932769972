import { Button, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  sectionButton: {
    maxWidth: "100px",
    margin: "20px auto auto",
    fontWeight: "bold",
    cursor: "pointer",
  },
});

interface ProgressButtonProps {
  text: string;
  onClick: () => void;
  disabled: boolean;
  hidden: boolean;
}

function ProgressButton({
  text,
  onClick,
  disabled,
  hidden,
}: ProgressButtonProps) {
  const styles = useStyles();

  if (hidden) return null;

  return (
    <Button
      className={styles.sectionButton}
      key={text}
      variant="outlined"
      color="primary"
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
}

export default ProgressButton;
