import { useEffect, useRef, useState } from "react";

function useCustomForm({
  initialValues,
  onSubmit,
  setErrors,
  itemNum,
  updateChildValues,
}: any) {
  const [values, setValues] = useState(initialValues);

  const formRendered = useRef(true);

  function validateErrorsAndSet(itemName: string, item: any) {
    const itemError = item.validate(item.value);
    const returnItem =
      itemError !== null
        ? { ...item, touched: true, error: true, errorMessage: itemError }
        : { ...item, touched: true, error: false, errorMessage: "" };

    setValues({ ...values, [itemName]: returnItem });
  }

  useEffect(() => {
    const keys = Object.keys(values);
    const anyUntouched = keys
      .map((key) => {
        return !!values[key].nonMandatory ? true : !!values[key].touched;
      })
      .some((touched) => touched === false);

    const anyErrors = keys
      .map((key) => {
        return values[key].error;
      })
      .some((error) => error === true);

    setErrors(itemNum, anyUntouched || anyErrors);

    updateChildValues(itemNum, values);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (!formRendered.current) {
      setValues(initialValues);
    }
    formRendered.current = false;
  }, [initialValues]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { name, value } = target;

    event.persist();
    const item = { ...values[name], value };
    setValues({ ...values, [name]: item });
    validateErrorsAndSet(name, item);
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { name } = target;
    validateErrorsAndSet(name, values[name]);
  };

  const handleSubmit = (event: any) => {
    if (event) event.preventDefault();

    onSubmit({ values });
  };

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
  };
}

export default useCustomForm;
