import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";
import React from "react";
import useInputStyles from "./input-styles";

interface SelectGroupProps {
  label: string;
  value: string;
  error: boolean;
  errorMessage?: string;
  name: string;
  options: any[];
  onBlur: (e: any) => void;
  onChange: (e: any) => void;
}

function SelectGroup({
  label,
  value,
  name,
  error,
  errorMessage,
  options,
  onBlur,
  onChange,
}: SelectGroupProps) {
  const styles = useInputStyles();

  function renderOption(option: any) {
    return (
      <FormControlLabel
        className={styles.label}
        value={option.value}
        key={option.value}
        control={<Radio />}
        label={option.text}
      />
    );
  }

  function renderOptions() {
    return options.map((option) => renderOption(option));
  }

  return (
    <>
      <FormLabel className={styles.legend} component="legend">
        {label}
      </FormLabel>
      <RadioGroup
        name={name}
        value={value}
        onBlur={(e: any) => onBlur(e)}
        onChange={(e: any) => onChange(e)}
      >
        {renderOptions()}
      </RadioGroup>
    </>
  );
}

export default SelectGroup;
