import { Typography } from "@material-ui/core";
import React from "react";
import Validations from "../../helpers/form-validation";
import SetIds from "../../helpers/set-ids";
import useCustomForm from "../../shared/CustomForm";
import FormInput from "../../shared/form-input";
import FormProps from "../../shared/form-props";
import SelectGroup from "../../shared/input-components/SelectGroup";
import sectionStyles from "../../shared/sectionStyles";
import StarterChecklistValueItems from "./starter-checklist-value-items";

const employeeOptions = [
  {
    value: "A",
    text:
      "This is my first job since 6 April and I’ve not been receiving taxable Jobseeker’s Allowance, Employment and Support Allowance, taxable Incapacity Benefit, State or Occupational Pension.",
  },
  {
    value: "B",
    text:
      "This is now my only job but since 6 April I’ve had another job, or received taxable Jobseeker’s Allowance, Employment and Support Allowance or taxable Incapacity Benefit. I do not receive a State or Occupational Pension.",
  },
  {
    value: "C",
    text:
      "As well as my new job, I have another job or receive a State or Occupational Pension.",
  },
];

const studentLoansOptions = [
  { value: "Yes", text: "Yes" },
  { value: "No", text: "No" },
];

const studentStudiesCompletedBeforeAprilOptions = [
  { value: "Yes", text: "Yes" },
  { value: "No", text: "No" },
];

const starterChecklistPrivateLoanRepaymentOptions = [
  { value: "Yes", text: "Yes" },
  { value: "No", text: "No" },
];

const starterChecklistStudentLoanTypeOptions = [
  { value: "Plan1", text: "Plan 1" },
  { value: "Plan2", text: "Plan 2" },
  { value: "Both", text: "Both" },
];

const starterChecklistPostGraduateLoanOptions = [
  { value: "Yes", text: "Yes" },
  { value: "No", text: "No" },
];

const starterChecklistPostGraduateStudiesCompleteBeforeAprilOptions = [
  { value: "Yes", text: "Yes" },
  { value: "No", text: "No" },
];

const starterChecklistPostGraduatePrivateRepaymentOptions = [
  { value: "Yes", text: "Yes" },
  { value: "No", text: "No" },
];

const initialValues: StarterChecklistValueItems = {
  StarterChecklistEmployeeStatement: {
    label: "Employee Statement",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
  },
  StarterChecklistStudentLoans: {
    label: "Do you have any UK undergraduate student loans?",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
  },
  StarterChecklistStudiesCompleteBeforeApril: {
    label: "Did you complete your studies before April 6th?",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
    nonMandatory: true,
  },
  StarterChecklistPrivateLoanRepayment: {
    label:
      "Are you repaying your Student Loan directly to the Student Loans Company by direct debit?",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
    nonMandatory: true,
  },
  StarterChecklistStudentLoanType: {
    label: "Type of Student Loan",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
    nonMandatory: true,
  },
  StarterChecklistPostGraduateLoan: {
    label: "Do you have a UK postgraduate loan which is not fully repaid?",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
  },
  StarterChecklistPostGraduateStudiesCompleteBeforeApril: {
    label:
      "Did you complete or leave your postgraduate studies before April 6th?",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
    nonMandatory: true,
  },
  StarterChecklistPostGraduatePrivateRepayment: {
    label:
      "Are you repaying your Postgraduate Loan direct to the Student Loans Company by direct debit?",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
    nonMandatory: true,
  },
};

function StarterChecklist({
  active,
  setErrors,
  itemNum,
  updateChildValues,
}: FormProps) {
  const styles = sectionStyles();
  const init: StarterChecklistValueItems = SetIds(initialValues);
  const { values, handleChange, handleBlur } = useCustomForm({
    initialValues: init,
    setErrors,
    itemNum,
    updateChildValues,
  });

  function changeMandatoryAndHandle(
    e: any,
    mandatory: boolean,
    dependents: string[]
  ) {
    dependents.forEach((item) => {
      values[item].nonMandatory = !mandatory;
    });

    handleChange(e);
  }

  return !active ? null : (
    <>
      <Typography
        className={`${styles.sectionTitle}`}
        variant="h5"
        component="h3"
      >
        Starter Checklist
      </Typography>

      <FormInput
        type={SelectGroup}
        obj={values.StarterChecklistEmployeeStatement}
        onBlur={handleBlur}
        onChange={handleChange}
        options={employeeOptions}
      />
      <FormInput
        type={SelectGroup}
        obj={values.StarterChecklistStudentLoans}
        onBlur={handleBlur}
        onChange={(e: any) =>
          changeMandatoryAndHandle(e, e.target.value === "Yes", [
            values.StarterChecklistStudiesCompleteBeforeApril.id,
            values.StarterChecklistPrivateLoanRepayment.id,
            values.StarterChecklistStudentLoanType.id,
          ])
        }
        options={studentLoansOptions}
      />

      {values.StarterChecklistStudentLoans.value !== "Yes" ? null : (
        <>
          <FormInput
            type={SelectGroup}
            obj={values.StarterChecklistStudiesCompleteBeforeApril}
            onBlur={handleBlur}
            onChange={handleChange}
            options={studentStudiesCompletedBeforeAprilOptions}
          />

          <FormInput
            type={SelectGroup}
            obj={values.StarterChecklistPrivateLoanRepayment}
            onBlur={handleBlur}
            onChange={handleChange}
            options={starterChecklistPrivateLoanRepaymentOptions}
          />

          <FormInput
            type={SelectGroup}
            obj={values.StarterChecklistStudentLoanType}
            onBlur={handleBlur}
            onChange={handleChange}
            options={starterChecklistStudentLoanTypeOptions}
          />
        </>
      )}

      <FormInput
        type={SelectGroup}
        obj={values.StarterChecklistPostGraduateLoan}
        onBlur={handleBlur}
        onChange={(e: any) =>
          changeMandatoryAndHandle(e, e.target.value === "Yes", [
            values.StarterChecklistPostGraduateStudiesCompleteBeforeApril.id,
            values.StarterChecklistPostGraduatePrivateRepayment.id,
          ])
        }
        options={starterChecklistPostGraduateLoanOptions}
      />

      {values.StarterChecklistPostGraduateLoan.value !== "Yes" ? null : (
        <>
          <FormInput
            type={SelectGroup}
            obj={values.StarterChecklistPostGraduateStudiesCompleteBeforeApril}
            onBlur={handleBlur}
            onChange={handleChange}
            options={
              starterChecklistPostGraduateStudiesCompleteBeforeAprilOptions
            }
          />
          <FormInput
            type={SelectGroup}
            obj={values.StarterChecklistPostGraduatePrivateRepayment}
            onBlur={handleBlur}
            onChange={handleChange}
            options={starterChecklistPostGraduatePrivateRepaymentOptions}
          />
        </>
      )}
    </>
  );
}

export default StarterChecklist;
