import { KeyboardDatePicker } from "@material-ui/pickers";
import React from "react";
import useInputStyles from "./input-styles";

interface DateInputProps {
  label: string;
  name: string;
  value: string;
  error: boolean;
  noFutureDates: boolean;
  onBlur: (e: any) => void;
  onChange: (e: any) => void;
}

function toDate(dateString: any) {
  if (dateString === undefined || dateString === "") return null;
  const [date, month, year] = dateString.split("/");
  return new Date(year, month - 1, date).toString();
}

function DateInput({
  label,
  name,
  value,
  error,
  noFutureDates,
  onBlur,
  onChange,
}: DateInputProps) {
  const styles = useInputStyles();

  return (
    <KeyboardDatePicker
      label={label}
      error={error}
      name={name}
      className={`${styles.input}`}
      inputVariant="outlined"
      onBlur={(e: any) => onBlur(e)}
      onChange={(_, value) =>
        onChange({ target: { name, value }, persist() { } })
      }
      format="dd/MM/yyyy"
      maxDate={noFutureDates ? new Date() : undefined}
      value={toDate(value)}
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
    >
      {label}
    </KeyboardDatePicker>
  );
}

export default DateInput;
