import axios from 'axios';
import ApiResponse from '../shared/api-response';
import ResponseType from '../shared/response-type';
import User from '../shared/user';

const config = {
  baseURL: process.env.REACT_APP_API_ENDPOINT,
};

const getAuthConfig = (authToken: string) => ({ ...config, headers: { Authorization: `Bearer ${authToken}` } })
let type = ResponseType.success;

interface UserResult {
  users: string[];
  error: boolean;
  Type: ResponseType;
}

interface NewAndiResult {
  users: User[];
  error: boolean;
  Type: ResponseType;
}

class ApiHelper {
  static getAndi: (email: string) => Promise<ApiResponse> = (userId) => {
    return axios.get(`/api/payrollform/${userId}`, config).then((res) => {

      switch (res.status) {
        case 200:
          const { firstName, lastName } = res.data;
          return { type: ResponseType.success, firstName, lastName };
        case 409: {
          const { reason } = res.data;
          return { type: ResponseType.submitted, reason }
        }
        default: {
          const { reason } = res.data;
          return { type: ResponseType.error, reason }
        }
      }

    }).catch(() => {
      return { type: ResponseType.error }
    });
  }

  static postForm: (userId: string, form: any) => Promise<ApiResponse> = (userId, form) => {
    return axios.post(`/api/payrollform/${userId}`, form, config).then((res) => {

      const { reason } = res.data;
      switch (res.status) {
        case 200:
          return { type: ResponseType.success, reason };
        case 409: {
          return { type: ResponseType.submitted, reason }
        }
        default: {
          return { type: ResponseType.error, reason }
        }
      }

    }).catch(() => {
      return { type: ResponseType.error }
    });
  }

  static resendEmail: (peopleHrId: string, authToken: string) => Promise<boolean> = async (peopleHrId, authToken) => {
    try {
      const res = await axios.post(`/api/admin/ResendEmail?peopleHrId=${peopleHrId}`, undefined, getAuthConfig(authToken));

      return res.status === 200 ? true : false;
    } catch {
      return false;
    }
  }

  static addUser: (email: string, authToken: string) => Promise<boolean> = async (email, authToken) => {
    try {
      const res = await axios.post(`/api/admin/addUser?email=${email}`, undefined, getAuthConfig(authToken));
      return res.status === 200 ? true : false;
    } catch {
      return false;
    }
  }

  static deleteUser: (email: string, authToken: string) => Promise<boolean> = async (email, authToken) => {
    try {
      const res = await axios.delete(`/api/admin/deleteUser?email=${email}`, getAuthConfig(authToken));
      return res.status === 200 ? true : false;
    } catch {
      return false;
    }
  }

  static getUsers: (authToken: string) => Promise<UserResult> = async (authToken) => {
    try {
      const res = await axios.get('/api/admin/GetUsers', getAuthConfig(authToken));
      if(res.status === 401)
      {
        type = ResponseType.unauthorized
      }
      return { users: res.data as string[], error: false, Type: type };
    } catch(err) {
      if( err.message.includes("401"))
      {
        type = ResponseType.unauthorized
      }
      return { users: [], error: true, Type: type };
    }
  }

  static getNewAndis: (authToken: string) => Promise<NewAndiResult> = async (authToken) => {
    try {
      const res = await axios.get('/api/admin', getAuthConfig(authToken));
      return { users: res.data as User[], error: false, Type: type };
    } catch(err) {
      if( err.message.includes("401"))
      {
        type = ResponseType.unauthorized
      }
      return { users: [], error: true, Type: type };
    }
  }

}

export default ApiHelper;