import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ApiHelper from '../../helpers/api-helper';
import Spinner from '../../shared/Spinner';
import AddUserButton from './add-user-button';
import AdminError from './admin-error';
import AuthToken from './authToken';
import DeleteUserButton from './delete-user-button';
import Logout from './admin-logout';
import ResponseType from '../../shared/response-type';

function CurrentUsers({ token }: AuthToken) {
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoaded, setIsLoaded] = useState(false);
  const [type, setType] = useState(ResponseType.error);

  useEffect(() => {
    let isSubscribed = true;
    ApiHelper.getUsers(token).then((res) => {
      if (isSubscribed) {
        setError(res.error);
        setUsers(res.users);
        setIsLoaded(true);
        setType(res.Type);
      }
    });

    return () => { isSubscribed = false; }
  }, [setError, setUsers, token, setType]);

  if(type === ResponseType.unauthorized)
  {
    Logout();
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRows = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const userAdded = (email: string) => setUsers([...users, email]);

  const userExists = (email: string) => users.indexOf(email) > -1;

  const userDeleted = (email: string) => {
    const prevUsers = [...users];
    const i = prevUsers.indexOf(email);


    if (i > -1) {
      prevUsers.splice(i, 1);

      setUsers(prevUsers);
    }
  }

  return !isLoaded ? <Spinner /> :
    error ? <AdminError /> : (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell align="right"><TextField label="Filter" type="search" variant="outlined" onChange={(e) => setSearch(e.target.value)} /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row"></TableCell>
              <AddUserButton authToken={token} checkIfUserExists={userExists} userAddedCallback={userAdded} />
            </TableRow>
            {users.filter((data) => !search || data.toLowerCase().includes(search.toLowerCase())
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((userEmail) => (
                <TableRow key={userEmail}>
                  <TableCell component="th" scope="row">
                    {userEmail}
                  </TableCell>
                  <DeleteUserButton email={userEmail} authToken={token} callback={userDeleted} />
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination rowsPerPage={rowsPerPage} count={users.length} component="div" page={page} onChangePage={handleChangePage} onChangeRowsPerPage={handleChangeRows} />
      </>
    )
}

export default CurrentUsers;