import { TextField } from "@material-ui/core";
import React from "react";
import MaskedInput from "react-text-mask";
import useInputStyles from "./input-styles";

interface TextInputProps {
  label: string;
  value: string;
  error: boolean;
  errorMessage?: string;
  name: string;
  onBlur: (e: any) => void;
  onChange: (e: any) => void;
}

interface TextMaskSortCode {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TextMaskSortCode(props: TextMaskSortCode) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

function setCursorLeft(e: React.RefObject<any>) {
  if (!/[0-9]/.test(e.current.value)) {
    e.current.setSelectionRange(0, 0)
  }
}

interface State {
  sortCode: string;
}

function SortCodeInput({
  label,
  value,
  name,
  error,
  errorMessage,
  onBlur,
  onChange,
}: TextInputProps) {
  const styles = useInputStyles();
  const inputRef = React.createRef<any>();
  return (
    <>
      <TextField
        className={styles.input}
        variant="outlined"
        label={label}
        name={name}
        inputRef={inputRef}
        value={value}
        onBlur={(e: any) => onBlur(e)}
        onChange={(e: any) => onChange(e)}
        onSelect={() => setCursorLeft(inputRef)}
        error={error}
        helperText={errorMessage}
        InputProps={{
          inputComponent: TextMaskSortCode as any,
        }}
      >
        {label}
      </TextField>
    </>
  );
}

export default SortCodeInput;
