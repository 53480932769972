import { Typography } from "@material-ui/core";
import React from "react";
import contactRelationships from "../../data/contact_relationships.json";
import Validations from "../../helpers/form-validation";
import SetIds from "../../helpers/set-ids";
import useCustomForm from "../../shared/CustomForm";
import FormInput from "../../shared/form-input";
import FormProps from "../../shared/form-props";
import Dropdown from "../../shared/input-components/Dropdown";
import TextInput from "../../shared/input-components/TextInput";
import sectionStyles from "../../shared/sectionStyles";
import ContactValueItems from "./contact-value-items";

const initialValues: ContactValueItems = {
  ContactStreetName: {
    label: "Street / House Number",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
  },
  ContactRoad: {
    label: "Road",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
  },
  ContactCounty: {
    label: "County",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
  },
  ContactTown: {
    label: "Town",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
  },
  ContactPostCode: {
    label: "Postcode",
    value: "",
    validate: (val: string) => Validations.postcode(val),
  },
  EmergencyContactName: {
    label: "Full Name",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
  },
  EmergencyContactNumber: {
    label: "Contact Number",
    value: "",
    validate: (val: string) => Validations.phoneNumber(val),
  },
  EmergencyContactRelationship: {
    label: "Relationship To You",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
  },
};

function Contact({ active, setErrors, itemNum, updateChildValues }: FormProps) {
  const styles = sectionStyles();
  const init: ContactValueItems = SetIds(initialValues);
  const { values, handleChange, handleBlur } = useCustomForm({
    initialValues: init,
    setErrors,
    itemNum,
    updateChildValues,
  });

  return !active ? null : (
    <>
      <Typography
        className={`${styles.sectionTitle}`}
        variant="h5"
        component="h3"
      >
        About You
      </Typography>

      <Typography
        className={styles.sectionFormHeading}
        variant="h6"
        component="h3"
      >
        My Contact Details
      </Typography>

      <FormInput
        type={TextInput}
        obj={values.ContactStreetName}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <FormInput
        type={TextInput}
        obj={values.ContactRoad}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <FormInput
        type={TextInput}
        obj={values.ContactCounty}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <FormInput
        type={TextInput}
        obj={values.ContactTown}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <FormInput
        type={TextInput}
        obj={values.ContactPostCode}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <Typography
        className={styles.sectionFormHeading}
        variant="h6"
        component="h3"
      >
        Emergency Contact Details
      </Typography>

      <FormInput
        type={TextInput}
        obj={values.EmergencyContactName}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <FormInput
        type={TextInput}
        obj={values.EmergencyContactNumber}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <FormInput
        type={Dropdown}
        obj={values.EmergencyContactRelationship}
        onBlur={handleBlur}
        onChange={handleChange}
        items={contactRelationships}
      />
    </>
  );
}

export default Contact;
