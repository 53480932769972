import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ApiHelper from '../../helpers/api-helper';
import Spinner from '../../shared/Spinner';
import User from '../../shared/user';
import AdminError from './admin-error';
import AuthToken from './authToken';
import ResendEmailButton from './resend-email-button';
import ResponseType from '../../shared/response-type';
import Logout from './admin-logout';

function NewAndis({ token }: AuthToken) {
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const [error, setError] = useState(false);
  const [type, setType] = useState(ResponseType.error);
  const [isLoaded, setIsLoaded] = useState(false);


  useEffect(() => {
    let isSubscribed = true;
    ApiHelper.getNewAndis(token).then((res) => {
      if (isSubscribed) {
        setIsLoaded(true);
        setError(res.error);
        setUsers(res.users);
        setType(res.Type);
      }
    });
    console.log(setType);
    return () => { isSubscribed = false; }
  }, [setError, setUsers, token, setType]);

  if(type === ResponseType.unauthorized)
  {
    return Logout();
  }

  return !isLoaded ? <Spinner /> :
    error ? <AdminError /> : (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell align="center">PeopleHr Id</TableCell>
            <TableCell align="center">Start Date</TableCell>
            <TableCell align="center">Club</TableCell>
            <TableCell align="right"><TextField label="Filter" type="search" variant="outlined" onChange={(e) => setSearch(e.target.value)} /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.filter((data) => !search || data.firstName.toLowerCase().includes(search.toLowerCase())
          ).map((user) => (
            <TableRow key={user.peopleHrId}>
              <TableCell component="th" scope="row">
                {user.firstName}
              </TableCell>
              <TableCell align="center">{user.peopleHrId}</TableCell>
              <TableCell align="center">{user.startDate}</TableCell>
              <TableCell align="center">{user.club}</TableCell>
              <ResendEmailButton email={user.peopleHrId} authToken={token} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
}

export default NewAndis;