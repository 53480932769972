import React from "react";

interface FormInputProps {
  type: React.FC<any>;
  obj: any;
  items?: any;
  noFutureDates?: boolean;
  options?: any;
  onBlur: (e: any) => void;
  onChange: (e: any) => void;
}

function FormInput({
  type,
  obj,
  items,
  noFutureDates,
  options,
  onBlur,
  onChange,
}: FormInputProps) {
  const props: any = {
    label: obj.label,
    name: obj.id,
    value: obj.value,
    onBlur: (e: any) => onBlur(e),
    onChange: (e: any) => onChange(e),
    error: obj.error,
    errorMessage: obj.errorMessage,
  };

  if (!!items) props.items = items;

  if (!!options) props.options = options;

  if (noFutureDates !== undefined) props.noFutureDates = noFutureDates;

  return React.createElement(type, props);
}

export default FormInput;
