import {
  TextField
} from "@material-ui/core";
import React from "react";
import useInputStyles from "./input-styles";

interface TextInputProps {
  label: string;
  value: string;
  error: boolean;
  errorMessage?: string;
  name: string;
  onBlur: (e: any) => void;
  onChange: (e: any) => void;
}

function TextInput({
  label,
  value,
  name,
  error,
  errorMessage,
  onBlur,
  onChange,
}: TextInputProps) {
  const styles = useInputStyles();
  return (
    <>
      <TextField
        className={styles.input}
        variant="outlined"
        label={label}
        name={name}
        value={value}
        onBlur={(e: any) => onBlur(e)}
        onChange={(e: any) => onChange(e)}
        error={error}
        helperText={errorMessage}
      >
        {label}
      </TextField>
    </>
  );
}

export default TextInput;
