import { Typography } from "@material-ui/core";
import React from "react";
import ethnicities from "../../data/ethnicities.json";
import nationitilies from "../../data/nationalities.json";
import titles from "../../data/titles.json";
import Validations from "../../helpers/form-validation";
import SetIds from "../../helpers/set-ids";
import useCustomForm from "../../shared/CustomForm";
import FormInput from "../../shared/form-input";
import FormProps from "../../shared/form-props";
import DateInput from "../../shared/input-components/DateInput";
import Dropdown from "../../shared/input-components/Dropdown";
import TextInput from "../../shared/input-components/TextInput";
import sectionStyles from "../../shared/sectionStyles";
import AboutYouValueItems from "./about-you-value-items";

const initialValues: AboutYouValueItems = {
  Title: {
    label: "Title",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
  },
  FirstName: {
    label: "First Name",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
  },
  LastName: {
    label: "Last Name",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
  },
  KnownAs: {
    label: "Likes to Be Called",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
  },
  DateOfBirth: {
    label: "Date of Birth",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
  },
  Nationality: {
    label: "Nationality",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
  },
  PassportExpiry: {
    label: "Passport Expiry Date",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
  },
  Ethnicity: {
    label: "Ethnicity",
    value: "",
    validate: (val: string) => Validations.notEmpty(val),
  },
  NationalInsuranceNumber: {
    label: "National Insurance Number",
    value: "",
    validate: (val: string) => Validations.nin(val),
  },
};

interface AboutYouProps extends FormProps {
  firstName: string;
  lastName: string;
}

function AboutYou({
  active,
  setErrors,
  itemNum,
  updateChildValues,
  firstName,
  lastName
}: AboutYouProps) {
  const init: AboutYouValueItems = SetIds(initialValues);

  const styles = sectionStyles();
  const { values, handleChange, handleBlur } = useCustomForm({
    initialValues: init,
    setErrors,
    itemNum,
    updateChildValues,
  });

  values.FirstName = { ...values.FirstName, value: firstName, touched: true };
  values.LastName = { ...values.LastName, value: lastName, touched: true };

  return !active ? null : (
    <>
      <Typography
        className={`${styles.sectionTitle}`}
        variant="h5"
        component="h3"
      >
        About You
      </Typography>

      <FormInput
        type={Dropdown}
        obj={values.Title}
        onBlur={handleBlur}
        onChange={handleChange}
        items={titles}
      />

      <FormInput
        type={TextInput}
        obj={values.FirstName}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <FormInput
        type={TextInput}
        obj={values.LastName}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <FormInput
        type={TextInput}
        obj={values.KnownAs}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <FormInput
        type={DateInput}
        obj={values.DateOfBirth}
        onBlur={handleBlur}
        onChange={handleChange}
        noFutureDates={true}
      />

      <FormInput
        type={Dropdown}
        obj={values.Nationality}
        onBlur={handleBlur}
        onChange={handleChange}
        items={nationitilies}
      />

      <FormInput
        type={DateInput}
        obj={values.PassportExpiry}
        onBlur={handleBlur}
        onChange={handleChange}
        noFutureDates={false}
      />

      <FormInput
        type={Dropdown}
        obj={values.Ethnicity}
        onBlur={handleBlur}
        onChange={handleChange}
        items={ethnicities}
      />

      <FormInput
        type={TextInput}
        obj={values.NationalInsuranceNumber}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </>
  );
}

export default AboutYou;
