import { Box, Typography } from '@material-ui/core';
import React from 'react';
import useComponentStyles from '../payroll-form/form-styles';

function AdminError() {
  const componentStyles = useComponentStyles();

  return (
    <Box className={`${componentStyles.container} ${componentStyles.root}`}>

      <Typography>
        There was an error fetching users, please reload the page or contact an administrator.
        </Typography>

    </Box>)
}

export default AdminError;