import { Typography } from "@material-ui/core";
import React from "react";
import FormProps from "../../shared/form-props";
import sectionStyles from "../../shared/sectionStyles";

function Intro({ active }: FormProps) {
  const styles = sectionStyles();
  return !active ? null : (
    <div style={{ padding: "0 32px" }}>
      <Typography
        className={`${styles.sectionTitle} ${styles.introTitle}`}
        variant="h5"
        component="h3"
      >
        Hello future ANDi!
      </Typography>
      <Typography className={`${styles.sectionSubheading}`} variant="subtitle1">
        To make sure you get paid correctly - and on time - you just need to
        complete this form, which should take no more than five minutes. <br />
        <br />
        If you have any issues or questions, get in touch at payroll@and.digital{" "}
        <br />
        <br />
        Thanks!
      </Typography>
    </div>
  );
}

export default Intro;
