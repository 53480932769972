import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import useInputStyles from "./input-styles";

interface DropdownProps {
  label: string;
  name: string;
  items: string[];
  value: string;
  error: boolean;
  onBlur: (e: any) => void;
  onChange: (e: any) => void;
}

function Dropdown({
  label,
  name,
  items,
  value,
  error,
  onBlur,
  onChange,
}: DropdownProps) {
  const styles = useInputStyles();
  return (
    <FormControl variant="outlined" className={styles.input}>
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        label={label}
        name={name}
        value={value}
        onBlur={(e: any) => onBlur(e)}
        onChange={(e: any) => onChange(e)}
        error={error}
      >
        {items.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default Dropdown;
