import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  details: {
    flexDirection: "column",
    width: "100%",
    marginRight: "26px",
  },
  detailContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    "& + &": {
      marginTop: "5px",
    },
  },
  summary: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
}));

interface SummaryProps {
  summaryItems: [string, any, {}][];
  active: boolean;
}

const subHeadingValues = [
  ["Title", "FirstName", "LastName"],
  ["ContactStreetName", "ContactRoad", "ContactPostcode"],
  ["BankDetailsAccountNumber"],
  [],
];

function Summary({ summaryItems, active }: SummaryProps) {
  const classes = useStyles();

  function SectionDetails(values: any) {
    return Object.keys(values).map((key) => {
      const item = values[key];
      return (
        <div key={item.label} className={classes.detailContainer}>
          <Typography key="name" className={classes.secondaryHeading}>
            {item.label}
          </Typography>
          <Typography
            key="value"
            variant="h5"
            component="h3"
            className={classes.heading}
          >
            {item.value === "" ? "N/A" : item.value}
          </Typography>
        </div>
      );
    });
  }

  return !active ? null : (
    <Box className={classes.root}>
      {summaryItems.map((section, i) => {
        const [heading, , values]: [string, any, any] = section;
        return (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className={classes.summary}>
                <Typography className={classes.heading}>{heading}</Typography>
                <Typography className={classes.secondaryHeading}>
                  {subHeadingValues[i]
                    .map((item) => (values[item] ? values[item].value : ""))
                    .join(" ")}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.details}>{SectionDetails(values)}</div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}

export default Summary;
