function checkRegExp(pattern: RegExp, errorMessage: string, value: string) {
  if (value === "") {
    return "";
  }

  if (!pattern.test(value)) return errorMessage;

  return null;
}

class ValidationHelpers {
  static notEmpty = (value: string) => {
    if (value === "") {
      return "";
    }
    return null;
  };

  static nin = (value: string) =>
    checkRegExp(/^[a-zA-Z]{2}\d{6}[a-zA-Z]?$/, "Invalid number", value);

  static postcode = (value: string) =>
    checkRegExp(
      /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
      "Invalid post code",
      value
    );

  static email = (value: string) =>
    checkRegExp(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      "Invalid email",
      value
    )

  static phoneNumber = (value: string) =>
    checkRegExp(/[0-9]+(\.[0-9][0-9]?)?/, "Invalid number", value);

  static sortCode = (value: string) =>
    checkRegExp(/^\d{2}-\d{2}-\d{2}$/, "Invalid sort code", value);

  static accountNo = (value: string) =>
    checkRegExp(/^\d{8}$/, "Account number should be 8 digits long", value);
}

export default ValidationHelpers;
