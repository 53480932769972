import DateFnsUtils from "@date-io/date-fns";
import { CssBaseline, useMediaQuery } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ToastProvider } from 'react-toast-notifications';
import Admin from "./components/admin";
import AuthorizedRoute from "./components/authorized-route";
import Header from "./components/header";
import PayrollForm from "./components/payroll-form";

function App() {

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: { main: "#EC4846" },
          success: { main: "#5ac328" },
          type: prefersDarkMode ? "dark" : "light",
        },
        typography: {
          fontFamily: ["Poppins", "Arial"].join(","),
        },
      }),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <CookiesProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <CssBaseline />
            <Header />
            <Router>
              <Route exact path="/payroll/:userId" component={PayrollForm} />
              <AuthorizedRoute exact path="/admin" component={Admin} />
            </Router>
          </MuiPickersUtilsProvider>
        </CookiesProvider>
      </ToastProvider>
    </ThemeProvider>
  );
}

export default App;
