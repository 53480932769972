import { Box, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import GoogleLogin from 'react-google-login';
import { Redirect } from 'react-router-dom';
import Authorize from '../../helpers/authorization';
import sectionStyles from '../../shared/sectionStyles';
import useComponentStyles from '../payroll-form/form-styles';

const clientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID!;

interface LoginParams {
  referrer: string;
  setCookie: (name: string, value: string) => void;
}


function Login({ referrer, setCookie }: LoginParams) {
  const styles = useComponentStyles();
  const section = sectionStyles();
  const [complete, setComplete] = useState(false);
  const [failed, setFailed] = useState(false);
  const onSuccess = (res: any) => {
    Authorize(res.tokenId, setCookie).then(() => {
      setComplete(true);
    }).catch(() => {
      setFailed(true);
    });
  };

  const onFailure = (res: any) => {
    setFailed(true);
  }

  return complete ? <Redirect to={referrer} /> : (
    <Box className={`${styles.container} ${styles.root}`}>
      <Paper className={`${styles.section}`}>
        <Typography
          className={`${section.sectionTitle}`}
          variant="h5"
          component="h3"
        >
          Login
      </Typography>
        {failed && <Typography color="error">Login Failed</Typography>}
        <GoogleLogin
          clientId={clientId}
          buttonText="Login with Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={'single_host_origin'}
          isSignedIn={true}
        />
      </Paper>
    </Box>
  )
}

export default Login;