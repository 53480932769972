import { Button, makeStyles, TableCell } from '@material-ui/core';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import ApiHelper from '../../helpers/api-helper';
import Spinner from '../../shared/Spinner';

interface ResendEmailButtonProps {
  email: string;
  authToken: string;
}

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.success.main + '!important',
    color: 'white !important',
  },
}))

function ResendEmailButton({ email, authToken }: ResendEmailButtonProps) {
  const [showButton, setShowButton] = useState(true);
  const { addToast } = useToasts();
  const styles = useStyles();
  const [success, setSuccess] = useState(false);

  const resendEmail = async () => {
    setShowButton(false);
    const error = !await ApiHelper.resendEmail(email, authToken);

    if (error) {
      addToast('There was an error resending the email, please try again.', { appearance: 'error' });
    } else {
      addToast('Email sent.', { appearance: 'success' });
      setSuccess(true);
    }
    setShowButton(true);
  }

  function ResendButton() {
    if (success) {
      return <TableCell align="right"><Button className={styles.success} variant="contained" disabled>✓</Button></TableCell>;
    }

    return <TableCell align="right">
      {showButton ?
        <Button onClick={resendEmail} variant="contained" color="primary">Resend Email</Button> :
        <Spinner />}
    </TableCell>
  }

  return <ResendButton />

}

export default ResendEmailButton;