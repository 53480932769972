import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TableCell, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import ApiHelper from '../../helpers/api-helper';
import ValidationHelpers from '../../helpers/form-validation';
import Spinner from '../../shared/Spinner';

interface AddUserButtonProps {
  authToken: string;
  checkIfUserExists: (email: string) => boolean;
  userAddedCallback: (email: string) => void;
}

function AddUserButton({ authToken, checkIfUserExists, userAddedCallback }: AddUserButtonProps) {
  const [showButton, setShowButton] = useState(true);
  const { addToast } = useToasts();
  const [addClicked, setAddClicked] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);


  const addUser = async (email: string) => {
    setAddClicked(true);
    setShowButton(false);
    const error = !await ApiHelper.addUser(email, authToken);

    if (error) {
      addToast('There was an error adding the user, please try again.', { appearance: 'error' });
    } else {
      addToast(`User "${email}" added.`, { appearance: 'success' });
      userAddedCallback(email);
    }
    setDialogOpen(false);
    setAddClicked(false);
    setShowButton(true);
  }

  const cancelAdd = () => setDialogOpen(false);

  const showDialog = () => setDialogOpen(true);



  const AddEmailDialog = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [touched, setTouched] = useState(false);

    const updateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
      setTouched(true);
      const val = event.target.value;
      setEmail(val);

      const error = ValidationHelpers.email(val);

      setError(!!error || !val);
      setErrorMessage(!error ? '' : error);

      if (!error && checkIfUserExists(val)) {
        setError(true);
        setErrorMessage('User already exists')
      }
    }

    return (<Dialog
      open={dialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Add User</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please enter the email address of the user you wish to add:
        </DialogContentText>
        <TextField value={email} type="email" variant="outlined" onChange={updateEmail} fullWidth error={error} helperText={errorMessage} />
      </DialogContent>
      <DialogActions>
        {addClicked ? (
          <Spinner />
        ) : (
            <React.Fragment>
              <Button onClick={cancelAdd} color="primary">
                Cancel
      </Button>
              <Button disabled={error || !touched} onClick={() => addUser(email)} color="primary" autoFocus>
                Add
        </Button>
            </React.Fragment>
          )}
      </DialogActions>
    </Dialog>)
  }

  function AddButton() {
    return (
      <>
        <TableCell align="right">
          {showButton ?
            <Button onClick={showDialog} variant="contained" color="primary">Add Administrator</Button> :
            <Spinner />}
        </TableCell>
        <AddEmailDialog />
      </>
    )
  }

  return <AddButton />

}

export default AddUserButton;