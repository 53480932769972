import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import headerImage from "./header.png";


const useStyles = makeStyles((theme) => ({
  header: {
    width: "100%",
    display: "flex",
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    backgroundColor: theme.palette.background.paper,
  },
  stickyHeader: {
    [theme.breakpoints.down("md")]: {
      position: "sticky",
      top: "0",
      left: "0",
      zIndex: "1001",
    },
  },
}));

function Header() {
  const classes = useStyles();
  return (
    <img
      src={headerImage}
      alt="AND Digital logo"
      className={`${classes.header} ${classes.stickyHeader}`}
    ></img>
  );
}

export default Header;
