import {
  Button, Dialog,



  DialogActions, DialogContent,
  DialogContentText, DialogTitle
} from "@material-ui/core";
import React, { useState } from "react";
import Spinner from "../../shared/Spinner";

interface SubmitDialogInterface {
  open: boolean;
  cancelSubmit: () => void;
  submit: () => void;
}

function SubmitDialog({ open, submit, cancelSubmit }: SubmitDialogInterface) {
  const [submitClicked, setSubmitClicked] = useState(false);

  function submitItems() {
    setSubmitClicked(true);
    submit();
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Declaration</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          I confirm that the information I’ve given on this form is correct.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {submitClicked ? (
          <Spinner />
        ) : (
            <React.Fragment>
              <Button onClick={cancelSubmit} color="primary">
                Back
            </Button>
              <Button onClick={submitItems} color="primary" autoFocus>
                {"Agree & Submit"}
              </Button>
            </React.Fragment>
          )}
      </DialogActions>
    </Dialog>
  );
}

export default SubmitDialog;
