import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import ResponseType from '../../shared/response-type';
import useComponentStyles from '../payroll-form/form-styles';



const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: '360px',
    userSelect: 'none'
  },
  error: {
    color: theme.palette.error.main
  },
  success: {
    color: theme.palette.success.main
  },
  splashPage: {
    textAlign: 'center'
  },
  message: {
    whiteSpace: 'pre-line'
  }
}));

interface SplashPageProps {
  type: ResponseType;
  message: string;
}

function SplashPage({ type, message }: SplashPageProps) {
  const classes: any = useStyles();
  const componentStyles = useComponentStyles();

  function getIcon() {
    const iconClasses = `${classes.icon} ${classes[type]}`

    switch (type) {
      case ResponseType.error:
        return <div className={`splash-page__icon ${classes.icon} ${classes.error}`}>X</div>
      case ResponseType.submitted:
      case ResponseType.success:
        return <div className={`splash-page__icon ${classes.icon} ${classes.success}`}>✓</div>
      default:
        return <div className={`splash-page__icon ${iconClasses}`}>?</div>
    }
  }

  function getMessage() {
    if (message) {
      return message;
    }
    if (type === ResponseType.error) {
      return `Oops - there was an issue…

      Please note, you’re only allowed to submit the page once. If you need help, please contact payroll@and.digital.
      `
    }
  }

  return (
    <Paper className={`splash-page splash-page--${type} ${componentStyles.section} ${classes.splashPage}`}>
      {getIcon()}
      <Typography className={`splash-page__message ${classes.message}`} variant="h5" component="h3">
        {getMessage()}
      </Typography>
    </Paper>
  )

}

export default SplashPage;
