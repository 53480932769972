import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, TableCell } from '@material-ui/core';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import ApiHelper from '../../helpers/api-helper';
import Spinner from '../../shared/Spinner';

interface DeleteUserButtonProps {
  callback: (email: string) => void;
  email: string;
  authToken: string;
}

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.success.main + '!important',
    color: 'white !important',
  },
}))

function DeleteUserButton({ email, authToken, callback }: DeleteUserButtonProps) {
  const [showButton, setShowButton] = useState(true);
  const { addToast } = useToasts();
  const styles = useStyles();
  const [success, setSuccess] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const deleteUser = async () => {
    setDeleteClicked(true);
    setShowButton(false);
    const error = !await ApiHelper.deleteUser(email, authToken);

    if (error) {
      addToast('There was an error deleting the user, please try again.', { appearance: 'error' });
      setDeleteClicked(false);
      setDialogOpen(false);
      setShowButton(true);
    } else {
      addToast(`User "${email}" deleted.`, { appearance: 'success' });
      setSuccess(true);
      callback(email);
    }
  }

  const cancelDelete = () => setDialogOpen(false);

  const showDialog = () => setDialogOpen(true);

  function DeleteButton() {
    if (success) {
      return <TableCell align="right"><Button className={styles.success} variant="contained" disabled>✓</Button></TableCell>;
    }

    return (
      <>
        <TableCell align="right">
          {showButton ?
            <Button onClick={showDialog} variant="contained" color="primary">Delete</Button> :
            <Spinner />}
        </TableCell>
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete User</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the user {email}?
      </DialogContentText>
          </DialogContent>
          <DialogActions>
            {deleteClicked ? (
              <Spinner />
            ) : (
                <React.Fragment>
                  <Button onClick={cancelDelete} color="primary">
                    Cancel
          </Button>
                  <Button onClick={deleteUser} color="primary" autoFocus>
                    Delete User
            </Button>
                </React.Fragment>
              )}
          </DialogActions>
        </Dialog>
      </>
    )
  }

  return <DeleteButton />

}

export default DeleteUserButton;