import { AppBar, Box, makeStyles, Paper, Tab, TableContainer, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import CurrentUsers from './current-users';
import NewAndis from './new-andis';

const useStyles = makeStyles({
  tableContainer: {
    maxWidth: 900,
    width: '90%',
    margin: '0 auto',
    marginTop: 50,
  }
})

function Admin() {
  const styles = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [cookies] = useCookies(['userToken']);

  const authToken = cookies['userToken'];

  interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    )
  }

  const handleChange = (event: React.ChangeEvent<{}>, value: number) => {
    setTabValue(value);
  };


  return (
    <TableContainer className={styles.tableContainer} component={Paper}>
      <AppBar position="static" color="inherit">
        <Tabs value={tabValue} textColor="primary" indicatorColor="primary" variant="fullWidth" onChange={handleChange}>
          <Tab label="New ANDis" />
          <Tab label="Admins" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <NewAndis token={authToken} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <CurrentUsers token={authToken} />
      </TabPanel>

    </TableContainer>
  );

}

export default Admin;