import { Box, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiHelper from "../../helpers/api-helper";
import ApiResponse from "../../shared/api-response";
import ResponseType from "../../shared/response-type";
import Spinner from "../../shared/Spinner";
import ValueItems from "../../shared/value-items";
import AboutYou from "../about-you";
import BankDetails from "../bank-details";
import Contact from "../contact";
import FormStepper from "../form-stepper";
import Intro from "../intro";
import ProgressButton from "../progress-button";
import SplashPage from "../splash-page";
import StarterChecklist from "../starter-checklist";
import SubmitDialog from "../submit-dialog";
import Summary from "../summary";
import Form from "./form";
import useComponentStyles from "./form-styles";

interface PayrollProps {
  userId: string;
}

function PayrollForm() {
  const [activeStep, setActiveStep] = useState(0);
  const styles = useComponentStyles();
  const [apiResponse, setApiResponse] = useState<ApiResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { userId }: PayrollProps = useParams();
  const [steps, setSteps] = useState<[string, any, any][]>([
    ["Intro", Intro, {}],
    ["About You", AboutYou, {}],
    ["Contact", Contact, {}],
    ["Bank Details", BankDetails, {}],
    ["Starter Checklist", StarterChecklist, {}],
    ["Summary", Summary, {}],
  ]);
  const [validSteps, setValidSteps] = useState([
    true,
    false,
    false,
    true,
    false,
    true,
  ]);
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    ApiHelper.getAndi(userId).then((res) => {
      setApiResponse(res);
      setIsLoading(false);
    })
  }, [userId]);

  const submitForm = () => {
    const vals = steps.map((step) => step[2]);

    let form: ValueItems;

    vals.forEach((val) => {
      form = { ...form, ...val };
    });

    const formDetails: any = {};
    Object.keys(form!).forEach((key) => {
      const formItem = form[key];
      formDetails[formItem.id!] = formItem.value;
    });

    formDetails.UserId = userId;

    formDetails.BankDetailsSortCode = formDetails.BankDetailsSortCode.replace(
      /-/g,
      ""
    );

    ApiHelper.postForm(userId, formDetails).then((res) => {
      setApiResponse(res);
      setFormSubmitted(true);
      setSubmitDialogOpen(false);
    })
  };

  const setErrors = (step: number, errors: boolean) => {
    const newArr = [...validSteps];
    newArr[step] = !errors;

    setValidSteps(newArr);
  };

  const updateChildValues = (step: number, values: {}) => {
    const prevSteps = [...steps];
    prevSteps[step][2] = values;

    setSteps(prevSteps);
  };

  const shouldHideButton = () => formSubmitted || isLoading || apiResponse?.type !== ResponseType.success;

  const stepIsValid = !validSteps[activeStep];

  const goBack = () => setActiveStep(activeStep - 1);
  const goForward = () => setActiveStep(activeStep + 1);
  const lastStep = activeStep === steps.length - 1;

  const getPage = () => {
    if (isLoading)
      return <Spinner />

    if (formSubmitted || apiResponse?.type !== ResponseType.success)
      return <SplashPage type={apiResponse!.type} message={apiResponse!.reason!} />

    return <Form steps={steps} activeStep={activeStep} apiResponse={apiResponse} setErrors={setErrors} updateChildValues={updateChildValues} />
  }

  return (
    <>
      {activeStep === 0 ? null : (
        <FormStepper
          activeStep={activeStep - 1}
          steps={steps.slice(1, steps.length).map((s) => s[0])}
        />
      )}
      <Box className={`${styles.container} ${styles.root}`}>
        <Paper className={`${styles.section}`}>

          {getPage()}


          <SubmitDialog
            submit={submitForm}
            open={submitDialogOpen}
            cancelSubmit={() => setSubmitDialogOpen(false)}
          />

        </Paper>
        <div className={`${styles.sectionContainer}`}>
          <Box className={styles.buttonFooter}>
            <ProgressButton
              text="Back"
              onClick={goBack}
              disabled={false}
              hidden={shouldHideButton() || activeStep === 0}
            />
            <ProgressButton
              text="Next"
              onClick={goForward}
              disabled={stepIsValid}
              hidden={shouldHideButton() || lastStep}
            />
            <ProgressButton
              text="Submit"
              onClick={() => setSubmitDialogOpen(true)}
              disabled={false}
              hidden={!lastStep || formSubmitted}
            />
          </Box>
        </div>
      </Box>
    </>
  );
}

export default PayrollForm;
