import { makeStyles } from "@material-ui/core/styles";

const useComponentStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: "16px",
    margin: "auto",
    marginTop: "15px",
    maxWidth: "600px",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    padding: "32px 16px",
    backgroundColor: theme.palette.background.paper,
  },
  sectionFeature: {
    marginLeft: "32px",
    marginRight: "32px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-around",
    marginBottom: "25px",
  },
  introTitle: {
    fontSize: "48px",
  },
  sectionSubheading: {
    marginTop: "-15px",
    marginBottom: "30px",
    whiteSpace: "pre-line",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  simpleLink: {
    marginBottom: "30px",
    "& + &": {
      marginTop: "-30px",
    },
  },
  smallStepper: {
    position: "relative",
    padding: "0",
  },
  smallText: {
    marginTop: "5px",
  },
  small: {
    display: "flex",
    flexDirection: "column",
    padding: "16px 0 0 0",
    justifyContent: "center",
    alignItems: "center",
  },
  "offline--section": {
    height: "80vh",
    position: "relative",
    "&:after": {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      "-webkit-transform": "translateX(-100%)",
      transform: "translateX(-100%)",
      backgroundImage:
        "linear-gradient(0.25turn, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
      "-webkit-animation": "$shimmer 2s infinite",
      animation: "$shimmer 2s infinite",
      content: '""',
    },
  },
  "@keyframes shimmer": {
    "100%": {
      "-webkit-transform": "translateX(100%)",
      transform: "translateX(100%)",
    },
  },
  buttonFooter: {
    display: "flex",
  },
}));

export default useComponentStyles;
